import {
  includes,
  pluck,
  intersection,
  isEmpty,
  reduce,
  last,
  append,
  pipe,
  length,
  without,
  concat,
  uniq,
  filter,
} from 'ramda';
import TagPresenter from 'presenters/TagPresenter';

const isChecked = (tag, currentTagsIds) => includes(tag.id, currentTagsIds);

const isIndeterminate = (tag, currentTagsIds) => {
  const children = TagPresenter.tagChildren(tag);
  const allChildrenIds = pluck('id', children);
  const intersectionCount = intersection(allChildrenIds, currentTagsIds).length;

  return (
    !isEmpty(children) &&
    ((isChecked(tag, currentTagsIds) && intersectionCount < children.length) ||
      (!isChecked(tag, currentTagsIds) && intersectionCount > 0))
  );
};

const changeParentTags = (parentTags, childTag, currentTagsIds, condition) => {
  return reduce(
    (acc, parentTag) => {
      const currentChildId = last(acc);
      if (condition(parentTag, currentChildId, currentTagsIds)) return append(parentTag.id, acc);

      return acc;
    },
    [childTag.id],
    parentTags,
  );
};

const isNeedToCheckParentTag = (parentTag, childTagId, currentTagsIds) => {
  const childrenIds = pluck('id', parentTag.children);
  const intersectionCount = pipe(intersection(currentTagsIds), append(childTagId), length)(childrenIds);

  return intersectionCount === childrenIds.length && !includes(parentTag.id, currentTagsIds);
};

const isNeedToUncheckParentTag = (parentTag, childTagId, currentTagsIds) => {
  const childrenIds = pluck('id', parentTag.children);
  const intersectionCount = pipe(without([childTagId]), intersection(currentTagsIds), length)(childrenIds);

  return intersectionCount < childrenIds.length && includes(parentTag.id, currentTagsIds);
};

const isAllChildrenChecked = (tag, currentTagsIds) => {
  const children = TagPresenter.tagChildren(tag);
  const allChildrenIds = pluck('id', children);
  const intersectionCount = intersection(allChildrenIds, currentTagsIds).length;

  return !isEmpty(children) && intersectionCount === children.length;
};

const checkTags = (tags, checkedTag, currentTagsIds) => {
  const parents = TagPresenter.tagParentsWithPluralChilds(checkedTag, tags);
  const children = TagPresenter.tagChildren(checkedTag);
  const allChildrenIds = isEmpty(children) ? [] : append(checkedTag.id, pluck('id', children));

  let changedTags = [];
  if (isChecked(checkedTag, currentTagsIds)) {
    const uncheckedParents = changeParentTags(parents, checkedTag, currentTagsIds, isNeedToUncheckParentTag);

    changedTags = pipe(without(uncheckedParents), without(allChildrenIds))(currentTagsIds);
  } else {
    const checkedParents = changeParentTags(parents, checkedTag, currentTagsIds, isNeedToCheckParentTag);

    changedTags = pipe(concat(checkedParents), concat(allChildrenIds), uniq)(currentTagsIds);
  }

  return changedTags;
};

const checkTagsWithoutParents = (tag, currentTagsIds) => {
  const children = TagPresenter.tagChildren(tag);
  const childrenIds = isEmpty(children) ? [] : pluck('id', children);

  let checkedTags = [];
  if (isChecked(tag, currentTagsIds)) {
    checkedTags = without([tag.id], currentTagsIds);
  } else if (isEmpty(childrenIds)) {
    checkedTags = append(tag.id, currentTagsIds);
  } else if (isAllChildrenChecked(tag, currentTagsIds)) {
    checkedTags = without(childrenIds, currentTagsIds);
  } else {
    checkedTags = pipe(concat(childrenIds), uniq)(currentTagsIds);
  }

  return checkedTags;
};

const getTagNames = (tags, tagIds) => {
  const condition = tag => tagIds.includes(tag.id);
  const names = pipe(filter(condition), pluck('name'))(tags);

  return names;
};

export { isChecked, isIndeterminate, checkTags, checkTagsWithoutParents, getTagNames };
